import React from 'react';
import styled from 'styled-components';
import { Row, Col } from 'styled-bootstrap-grid';
import Textbox from '../text/Textbox';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';

const NewsTitle = styled.div`
  text-align: center;
  color: #000;
  padding: 30px 0 0 0;
  font-size: 28px;
  & h1 {
    display: inline;
  }
  @media (max-width: 767px) {
    font-size: 27px;
  }
  @media (max-width: 420px) {
    font-size: calc(21px + (28 - 21) * ((100vw - 300px) / (420 - 300)));
  }
`;

const NewsContainer = styled.div`
  max-width: 655px;
  text-align: left;
  margin: 0 auto;
  font-size: 28px;
  @media (max-width: 767px) {
    font-size: 27px;
  }
  @media (max-width: 420px) {
    font-size: calc(21px + (28 - 21) * ((100vw - 300px) / (420 - 300)));
  }
`;

const NewsDetails = styled.div`
  margin-bottom: 40px;
`;

const News = ({ data: { prismicNews } }) => {
  const { data } = prismicNews;

  return (
    <>
      <Helmet
        title={`${
          data.title.text ? data.title.text + ' - ' : ''
        }Askeaton Contemporary Arts`}
      />
      <Row justifyContent="center">
        <Col col={12} md={8}>
          {data.image.url && (
            <img
              src={data.image.url}
              alt={data.image.alt || 'News image'}
              style={{ width: '100%', height: 'auto' }}
            />
          )}
          <NewsTitle dangerouslySetInnerHTML={{ __html: data.title.html }} />
          <Textbox
            margin={`20px auto`}
            mobileMargin={`26px auto 20px auto`}
            textAlign={`left`}
            text={data.text.html}
          />
        </Col>
      </Row>
    </>
  );
};

export default News;

export const pageQuery = graphql`
  query NewsBySlug($uid: String!) {
    prismicNews(uid: { eq: $uid }) {
      uid
      data {
        title {
          text
          html
        }
        text {
          html
        }
        image {
          url
          alt
          dimensions {
            width
            height
          }
        }
        date
      }
    }
  }
`;
